.announcement-bar {
    background-color: $gold;
    color: $black;
    padding: 10px 0;
    p {
        margin: 0;
    }
    a {
        color: $pink;
        &:hover {
            text-decoration: underline;
        }
    }
}