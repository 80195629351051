footer {
    clear: both;

    .contact {
        padding: 30px 0;
        font-size: 0.75rem;
        background-color: $dark-blue;
        color: $white;

        .logo {
            display: block;
            margin-bottom: 15px;
        }

        a {
            text-decoration: underline;
            font-weight: $font-weight-bold;
            color: $white;
        }
    }

    .social {
        display: flex;

        a {
            display: block;
            margin-right: 1rem;
            color: $white;

            &:hover {
                color: $yellow;
            }

            svg {
                width: 1.5em;
                height: 1.25em;
            }
        }
    }

    .sitemap {
        padding: 30px 0;

        h2 {
            @include smallcaps;
            margin-bottom: 2rem;
            padding-bottom: 3px;
            border-bottom: 1px solid $light-grey;
        }

        a.section {
            display: block;
            margin-bottom: 1rem;
            padding-bottom: 0.5rem;
            font-family: $font-family-sans-alt;
            font-weight: $font-weight-medium;
            font-size: 0.75rem;
            letter-spacing: 0.13px;
            color: $dark-blue;
            border-bottom: 1px solid $teal;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                margin-bottom: 0.375rem;
                letter-spacing: 0.13px;
                font-size: 0.625rem;
                line-height: 1.25;
            }

            a {
                color: $body-color;

                &:hover {
                    text-decoration: underline;
                }
            }

            ul {
                margin-top: 0.375rem;
                padding-left: 0.625rem;
                border-left: 1px solid $lightest-grey;
            }
        }
    }
}
