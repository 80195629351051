.breadcrumb {
    @include eyebrow;
    margin-bottom: 1.5rem;
    color: $grey;

    a {
        color: $grey;
    }

    @include md {
        & + h1.large {
            margin-top: 2.5rem;
        }
    }
}

.service-card {
    @include card;

    span.learn-more {
        @include link-medium;
        transition: color 0.25s;
    }

    &:hover {
        background-color: $lightest-grey;

        span.learn-more {
            color: $blue;
        }
    }
}

.location-card {
    @include card;
    padding-bottom: 60px;

    p {
        margin-bottom: 0.5rem;
    }

    &:hover {
        .learn-more-block {
            background-color: $teal;
            color: $white;
        }
    }
}

.physician-card {
    @include card;
    padding-bottom: 60px;

    .headshot {
        display: block;
        width: 116px;
        height: 116px;
        margin-bottom: 1rem;
        border-radius: 50%;
    }

    .title {
        position: relative;
        margin-bottom: 20px;
        padding-bottom: 20px;

        &::after {
            content: " ";
            position: absolute;
            left: 0;
            bottom: 0;
            display: block;
            width: 40px;
            height: 1px;
            background-color: $lightest-grey;
        }
    }

    p {
        margin-bottom: 0.5rem;
    }

    &:hover {
        .learn-more-block {
            background-color: $teal;
            color: $white;
        }
    }
}

.article-card {
    @include card;

    span.learn-more {
        @include link-medium;
        transition: color 0.25s;
    }

    &:hover {
        span.learn-more {
            color: $blue;
        }
    }
}

.event-card {
    @include card;
    padding-bottom: 60px;

    p {
        margin-bottom: 0.5rem;
    }

    &:hover {
        .learn-more-block {
            background-color: $teal;
            color: $white;
        }
    }
}

.cta-card {
    position: relative;
    display: block;
    margin-bottom: 1.5rem;
    padding: 20px 20px 20px 160px;
    border: 1px solid $teal;
    border-top-left-radius: 45px;
    border-bottom-right-radius: 45px;
    overflow: hidden;
    transition: box-shadow 0.4s;

    &.animate {
        transition: box-shadow 0.4s, opacity 0.5s, transform 1s cubic-bezier(0, 0, 0, 1);
    }

    &::before {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        width: 130px;
        height: 100%;
        background-color: $teal;
    }

    .learn-more {
        @include eyebrow;
        margin-bottom: 0;
        text-decoration: underline;
        color: $teal;
    }

    &:hover {
        box-shadow: 0 2px 4px rgba(0,0,0,0.25);

        .learn-more {
            color: $blue;
        }
    }

    @include md {
        padding: 30px 60px 30px 170px;
    }
}

.cta-tablet {
    border-color: $teal;

    &::before {
        background: $teal url('../img/cta-tablet.svg') 50% 50% no-repeat;
        background-size: 75% auto;
    }
}

.cta-laptop {
    border-color: $pink;

    &::before {
        background: $pink url('../img/cta-laptop.svg') 50% 50% no-repeat;
        background-size: 65% auto;
    }
}

.cta-family {
    border-color: $gold;

    &::before {
        background: $gold url('../img/cta-family.svg') 50% 50% no-repeat;
        background-size: 60% auto;
    }
}

.image-callout {
    position: relative;
    padding: 30px;
    color: $white;
    border-bottom-right-radius: 100px;
    background-color: $dark-blue;

    .bg {
        @include fill;
        z-index: 1;
        @include cover;
        background-image: url('../img/bg-pattern-1.svg');
        border-bottom-right-radius: 100px;
        overflow: hidden;
        opacity: 0.25;

        &.animate {
            opacity: 0;
            transition: opacity 1s;
        }

        &.active {
            opacity: 0.25;
        }
    }

    .image {
        position: relative;
        z-index: 2;
        margin-bottom: 2rem;
        padding-top: 75%;
        @include cover;
    }

    .content {
        position: relative;
        z-index: 2;
        max-width: 640px;

        h1, h2, h3, h4, h5, h6 {
            color: $white;
        }

        *:last-child {
            margin-bottom: 0;
        }
    }

    @include md {
        display: flex;
        align-items: center;
        padding: 0;

        .image {
            position: absolute;
            left: 0;
            top: 0;
            width: 33.33%;
            height: 100%;
            padding-top: 0;
        }

        .content {
            margin-left: 33.33%;
            padding: 40px 50px;
        }
    }

    @include lg {
        .content {
            padding: 70px 80px;
        }
    }

    @include xl {
        max-width: map-get($container-max-widths, xl) - ($spacer * 2);
        margin: 0 auto;

        &::before {
            content: " ";
            @include fill;
            left: auto;
            right: 100%;
            background-color: $dark-blue;
        }

        .image {
            width: 40%;
        }

        .content {
            margin-left: 40%;
        }
    }
}

.event-callout {
    position: relative;
    padding: 30px 30px 0;
    color: $white;
    background-color: $dark-blue;
    overflow: hidden;

    .image {
        position: relative;
        z-index: 2;
        width: calc(100% + 60px);
        margin: 1.5rem -30px 0;
        padding-top: calc(100% + 60px);
        @include cover;
    }

    .content {
        position: relative;
        z-index: 2;
        max-width: 640px;

        h1, h2, h3, h4, h5, h6 {
            color: $white;
        }

        a {
            color: $yellow;
        }
    }

    @include sm {
        padding: 30px;

        .content {
            margin-right: 30px;

            *:last-child {
                margin-bottom: 0;
            }
        }

        .image {
            width: 100%;
            margin: 0;
            padding-top: 100%;
            @include cover;
        }
    }

    @include md {
        padding: 0;

        .content {
            margin-right: 0;
            padding: 40px 50px;
        }
    }

    @include lg {
        .content {
            padding: 70px 80px;
        }
    }
}

.blog-excerpts {
    position: relative;
    padding: 30px;
    box-shadow: 0 0 2px rgba(0,0,0,0.25);
    overflow: hidden;

    a.btn-block {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        margin-bottom: 0;
        border-radius: 0;
    }

    @include md {
        padding: 50px;
    }

    @include lg {
        padding: 70px;
    }

    @include xl {
        padding: 70px 100px;
    }
}

.excerpt {
    display: block;
    margin-bottom: 2rem;
    font-size: 0.9375rem;
    color: $body-color;

    &:hover {
        color: $body-color;

        .link-medium,
        .link-bold {
            color: $blue;
        }
    }

    @include lg {
        padding-right: 20px;
    }

    @include xl {
        padding-right: 40px;
    }
}

.upcoming-events {
    position: relative;
    padding: 30px 0;

    &.expanded {
        border-top-left-radius: 50px;
        background-color: $lightest-grey;
    }

    @include md {
        padding: 50px 0 30px;

        &.expanded {
            padding: 70px 50px;
            border-top-left-radius: 100px;
            background-color: $lightest-grey;
        }
    }

    @include lg {
        &.expanded {
            padding: 70px 0;
            border-top-left-radius: 100px;
            background-color: $lightest-grey;
        }
    }

    @include xl {
        &.expanded {
            max-width: map-get($container-max-widths, xl) - ($spacer * 2);
            margin: 0 auto;
            padding: 70px;

            &::before {
                content: " ";
                @include fill;
                left: 100%;
                background-color: $lightest-grey;
            }
        }
    }
}

.related-content {
    clear: both;
    position: relative;
    padding: 30px 0;
    border-top-left-radius: 50px;
    background-color: $lightest-grey;

    @include md {
        border-top-left-radius: 100px;
        padding: 50px 0 30px;
    }
}

figure {
    display: block;
    margin-bottom: 2rem;

    &.rounded-corner img {
        border-bottom-right-radius: 100px;
    }

    img {
        display: block;
        margin: 0 -30px 1rem -30px;
        max-width: none;
        width: calc(100% + 60px);
    }

    figcaption {
        font-style: italic;
        font-size: 0.75rem;
    }

    @include md {
        img {
            margin: 0 0 1rem;
            width: 100%;
        }
    }
}

blockquote {
    padding: 30px;
    background-color: $lightest-grey;
    box-shadow: 0 1px 2px rgba(0,0,0,0.25);
    border-bottom-right-radius: 100px;

    p {
        text-indent: -0.5rem;
        color: $body-color !important;
    }

    cite {
        font-style: normal;

        h5 {
            margin-bottom: 0.25rem;
        }

        img {
            margin-right: 1rem;
            border-radius: 50%;
        }
    }

    @include md {
        padding: 40px;
    }

    @include lg {
        padding: 50px;
    }
}

section.blockquote {
    position: relative;
    padding: 80px 0;
    background-color: $dark-blue;
    color: $white;

    .bg {
        @include fill;
        z-index: 1;
        @include cover;
        background-image: url('../img/bg-pattern-1.svg');
        opacity: 0.25;

        &.animate {
            opacity: 0;
            transition: opacity 1s;
        }

        &.active {
            opacity: 0.25;
        }
    }

    blockquote {
        position: relative;
        z-index: 2;
        padding: 0;
        background-color: transparent;
        box-shadow: none;

        p {
            margin-bottom: 2rem;
            text-align: center;
            color: $white !important;
        }
    }

    h6,
    .eyebrow {
        color: $white;
    }

    @include md {
        padding: 100px 0;
    }
}

.carousel {
    position: relative;
    margin-bottom: 2rem;

    .slide {
        display: block;
        width: 100%;

        .popup-video::before {
            opacity: 0;
            transform: scale(0.25);
            transition: opacity 0.25s linear 0.25s, transform 0.5s $ease-out-back-easy 0.25s;
        }

        &.is-selected .popup-video::before {
            opacity: 1;
            transform: scale(1);
        }
    }

    img {
        display: block;
        width: 100%;
    }

    .flickity-button {
        border-radius: 0;
        transition: background-color 0.25s;
    }

    .flickity-prev-next-button {
        width: 50px;
        height: 50px;
    }

    .flickity-prev-next-button.previous {
        left: 0;
    }

    .flickity-prev-next-button.next {
        right: 0;
    }

    .flickity-button-icon {
        fill: $blue;
    }

    &.carousel-sm {
        .flickity-button {
            background: transparent;
        }

        .flickity-button-icon {
            fill: $white;
        }
    }
}

.popup-video {
    position: relative;
    display: block;

    &::before {
        content: "";
        position: absolute;
        left: calc(50% - 25px);
        top: calc(50% - 25px);
        display: block;
        width: 50px;
        height: 50px;
        background: url('../img/play.svg') 50% 50% no-repeat;
        background-size: contain;
    }
}

.tabs {
    margin-bottom: 2rem;

    .tabs-nav {
        position: relative;
        margin-bottom: 1em;
        padding-bottom: 0.5rem;
        list-style-type: none;
        padding-left: 0;

        li {
            display: inline-block;
            margin-right: 1rem;
        }

        a {
            font-size: 0.8125rem;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            color: $blue;

            &:hover {
                color: $teal;
            }

            &.active {
                border-bottom: 1px solid $teal;
                color: $teal;
            }
        }

        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: $light-grey;
        }
    }

    @include md {
        .tabs-nav li {
            margin-right: 2rem;
        }
    }

    @include md {
        .tabs-nav li {
            margin-right: 3rem;
        }
    }
}

.profile {
    margin-bottom: 2rem;

    & + .profile {
        margin-top: -1rem;
    }

    h3, h5 {
        margin-bottom: 0.25rem;
    }

    h3 {
        font-weight: $font-weight-regular;
    }

    .eyebrow {
        margin-bottom: 0;
    }

    .photo {
        display: block;
        width: 100%;
        border-radius: 50%;
    }
}

.map-wrap {
    position: relative;
    margin-bottom: 2rem;
    padding-top: 65%;
    box-shadow: 0 0 2px rgba(0,0,0,0.25);
    transition: padding-top 1s $snappy;
    background-color: $white;

    > div {
        @include fill;
    }

    @include md {
        padding-top: 85%;
    }

    @include lg {
        padding-top: 70%;
    }

    @include xl {
        padding-top: 50%;
    }
}

.content-column {
    > *:last-child {
        margin-bottom: 0;
    }
}

.services-nav {
    position: relative;
    margin-bottom: 30px;
    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;

    ul {
        position: relative;
        left: 0;
        top: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            margin: 0 10px;

            &:first-child {
                margin-left: 0;
            }
        }

        a {
            display: block;
            padding: 15px 0;
            text-transform: uppercase;
            font-size: 0.8125rem;
            font-weight: $font-weight-bold;
            letter-spacing: 0.15px;
            color: $blue;
            white-space: nowrap;

            &:hover {
                color: $teal;
            }

            &.active {
                color: $teal;
                text-decoration: underline;
            }
        }
    }

    @include sm {
        ul {
            justify-content: flex-start;
        }
    }

    @include sm {
        ul li {
            margin: 0 20px;
        }
    }
}

nav.sitemap {
    ul {
        list-style: none;
        padding: 0;

        a {
            border-bottom: none !important;
        }
    }
}
