.form-control {
    border: none;
    border-bottom: 1px solid $blue;

    &:focus {
        box-shadow: none;
        border-bottom: 1px solid $teal;
    }
}

select.form-control {
    border-bottom: none;
    box-shadow: 0 1px 3px 0 transparentize($blue, 0.25);

    &:focus {
        border-bottom: none;
        box-shadow: 0 1px 3px 0 transparentize($teal, 0.25);
        background-color: transparentize($teal, 0.95);
    }
}

textarea.form-control {
    min-height: 100px;
    padding: 0.75rem 1rem;
    border: 1px solid $blue;
    border-bottom-right-radius: 50px;
    font-size: 0.75rem;

    &:focus {
        border: 1px solid $teal;
    }
}

label {
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: $font-weight-bold;
    letter-spacing: 0.18px;
    color: $blue;
}

// QGiv Widget Embed

.qgiv-embed-container {
    min-height: 2500px;

    iframe { min-height: 2500px; }

    @include sm {
        min-height: 2000px;

        iframe { min-height: 2000px; }
    }
}
