@mixin sm {
    @media (min-width: map-get($grid-breakpoints, 'sm')) { @content; }
}

@mixin md {
    @media (min-width: map-get($grid-breakpoints, 'md')) { @content; }
}

@mixin lg {
    @media (min-width: map-get($grid-breakpoints, 'lg')) { @content; }
}

@mixin xl {
    @media (min-width: map-get($grid-breakpoints, 'xl')) { @content; }
}

@mixin xxl {
    @media (min-width: 1440px) { @content; }
}

@mixin fill {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.fill { @include fill; }

@mixin cover {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}
.cover { @include cover; }

@mixin contain {
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}
.cover { @include cover; }

@mixin section-margin {
    margin: $spacer 0;

    @include md {
        margin: $spacer*2 0;
    }

    @include lg {
        margin: $spacer*3 0;
    }
}

@mixin section-padding {
    padding: $spacer 0;

    @include md {
        padding: $spacer*2 0;
    }

    @include lg {
        padding: $spacer*3 0;
    }
}

@mixin card {
    position: relative;
    display: block;
    margin-bottom: 30px;
    padding: 30px;
    background-color: $white;
    box-shadow: 0 0 2px rgba(0,0,0,0.25);
    border-bottom-right-radius: 45px;
    transition: background-color 0.25s, opacity 0.5s, transform 1s cubic-bezier(0, 0, 0, 1) !important;
    font-size: 0.9375rem;
    color: $body-color;
    overflow: hidden;

    &:hover {
        background-color: $lightest-grey;
        color: $body-color;
    }

    &:active {
        box-shadow: 0 0 6px rgba(0,0,0,0.25);
    }
}
.card { @include card; }

@mixin debug {
    outline: 1px solid red;
}
.debug { @include debug; }
