.calendar-header-link {
    display: block;
    width: 30px;
    height: 30px;

    svg {
        display: block;
        width: 30px;
        height: 30px;
        fill: $teal;
    }
}

.event-image {
    display: block;
    max-width: none;
    width: calc(100% + 60px);
    margin: 1rem -30px 0;
    border-bottom-left-radius: 100px;

    @include md {
        float: right;
        width: 320px;
        margin: 0;
        margin-right: calc((50vw - (720px / 2) + 30px) * -1);
    }

    @include lg {
        width: 400px;
        margin-right: calc((50vw - (894px / 2) + 30px) * -1);
    }
}
