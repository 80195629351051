.sidebar {
    font-size: 0.9375rem;

    .widget {
        display: block;
        margin-bottom: 1rem;
    }

    .promo img {
        display: block;
        width: 100%;
    }

    .content-box {
        padding: 25px;
        box-shadow: 0 0 2px rgba(0,0,0,0.25);
        overflow: hidden;

        > *:last-child {
            margin-bottom: 0;
        }
    }

    .submenu {
        padding: 0 25px;
        background-color: $dark-blue;
        box-shadow: 0 0 2px rgba(0,0,0,0.25);
        font-size: 0.8125rem;
        overflow: hidden;

        &.active {
            .toggle {
                margin-bottom: 30px;

                &::after {
                    display: block;
                }

                svg.icon {
                    transform: rotate(180deg);
                }
            }

            ul {
                height: auto;
                margin-bottom: 30px;
                opacity: 1;
                transform: translateY(0);
                transition: opacity 0.5s, transform 0.5s $snappy;
            }
        }


        .toggle {
            @include eyebrow;
            position: relative;
            display: block;
            margin: 0;
            padding: 15px 0;
            line-height: 20px;
            color: $white;

            &::before {
                content: "";
                position: absolute;
                right: -25px;
                top: 0;
                display: block;
                width: 50px;
                height: 100%;
                background-color: $secondary;
            }

            &::after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                display: none;
                width: 150%;
                height: 1px;
                background-color: $secondary;
            }

            &:hover {
                color: $white;
                cursor: pointer;
            }

            svg.icon {
                position: absolute;
                right: -7px;
                top: 17px;
                width: 0.9rem;
                height: 0.9rem;
                margin: 0;
                transition: transform 0.5s $snappy;
            }
        }

        ul {
            height: 0;
            margin: 0;
            padding: 0;
            list-style-type: none;
            opacity: 0;
            transform: translateY(-20px);
            transition: opacity 0.25s, transform 0.5s $snappy;
        }

        li {
            margin-bottom: 0.5rem;
            line-height: 1.25;
        }

        a {
            color: $white;

            &:hover {
                color: $teal;
            }
        }
    }

    .quick-links {
        padding: 25px;
        box-shadow: 0 0 2px rgba(0,0,0,0.25);
        overflow: hidden;

        > *:last-child {
            margin-bottom: 0;
        }

        ul {
            padding: 0;
            list-style: none;
        }

        a {
            color: $body-color;
        }
    }

    .share {
        display: flex;
        align-items: center;
        cursor: pointer;

        > .icon {
            transition: opacity 0.5s, transform 1s $snappy;
        }

        span {
            color: $grey;
        }

        a,
        span {
            display: block;
            margin: 0;
            padding: 10px 0;
        }

        a {
            text-decoration: none;
            opacity: 0;
            transition: opacity 0.5s, transform 1s $snappy;

            &:nth-child(3) { transform: translateX(-35px); }
            &:nth-child(4) { transform: translateX(-50px); }
            &:nth-child(5) { transform: translateX(-65px); }
        }

        .icon {
            display: block;
            width: 1rem;
            height: 1rem;
        }

        &.active {
            > .icon {
                opacity: 0;
                transform: translateX(12px);
            }

            a {
                opacity: 1;
                transform: translateX(-24px);
            }
        }
    }

    @include md {
        position: relative;

        &::before {
            content: "";
            display: block;
            float: left;
            width: 1px;
            height: 50vh;
            min-height: 600px;
        }

        aside {
            position: absolute;
            right: 0;
            top: 0;
            width: 210px;
        }
    }

    @include lg {
        aside {
            width: 230px;
        }
    }
}

section.hero + .sidebar-md,
section.hero + section.sidebar {
    margin-top: 30px;

    @include md {
        margin-top: 50px;
    }

    @include lg {
        margin-top: 70px;
    }
}
