header {
    position: relative;
    z-index: 10;
    height: 60px;
    background-color: $dark-blue;

    a.logo {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 100%;
        background-color: $dark-blue;
        transition: transform 0.5s $snappy;
    }

    a.mobile-nav-toggle {
        position: absolute;
        z-index: 1;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 100%;
        background-color: $teal;
        text-transform: uppercase;
        font-size: 0.8125rem;
        font-weight: $font-weight-medium;
        letter-spacing: 0.29px;
        color: $white;
        transition: width 0.5s $snappy;

        svg.icon {
            margin-left: 0.5rem;
            width: 1.15em;
            height: 1.15em;
            transition: transform 0.5s $ease-out-back-easy;
        }
    }

    a.desktop-nav-toggle,
    a.desktop-search-toggle {
        display: none;
    }

    .header-top form.search {
        display: none;
    }

    nav {
        position: absolute;
        left: 0;
        top: 60px;
        width: 100%;
        height: calc(100vh - 60px);
        background-color: $dark-blue;
        overflow: hidden;
        opacity: 0;
        transform: translateY(-100%);
        transition: opacity 0.35s, transform 0.5s $snappy;

        ul.main-menu {
            margin: 0;
            padding: 0;
            list-style: none;

            li.separator {
                display: none;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                font-family: $font-family-sans-alt;
                font-size: 0.9375rem;
                color: $white;
                border-bottom: 1px solid $teal;

                svg.icon {
                    flex-shrink: 0;
                    transition: transform 0.5s $ease-out-back-easy;
                }
            }
        }

        ul.sub-menu {
            margin-bottom: 20px;
            padding: 0;
            list-style: none;

            a {
                display: inline-block;
                padding: 10px 0;
                font-size: 0.875rem;
                color: $white;

                &:focus,
                &:active,
                &:hover {
                    color: $lightest-grey;
                }

                em {
                    display: none;
                }
            }
        }

        #main-menu form.search {
            margin: 20px 30px;
        }

        #main-menu {
            transform: translateX(-50%);
        }
        &.main-menu #main-menu {
            opacity: 1;
            transform: translateX(0);
            visibility: visible;
            transition: opacity 0.35s, transform 0.5s $snappy, visibility 0s linear 0s;
        }

        #physicians {
            transform: translateX(50%);
        }
        &.physicians #physicians {
            opacity: 1;
            transform: translateX(0);
            visibility: visible;
            transition: opacity 0.35s, transform 0.5s $snappy, visibility 0s linear 0s;
        }

        #services {
            transform: translateX(50%);
        }
        &.services #services {
            opacity: 1;
            transform: translateX(0);
            visibility: visible;
            transition: opacity 0.35s, transform 0.5s $snappy, visibility 0s linear 0s;
        }

        #locations {
            transform: translateX(50%);
        }
        &.locations #locations {
            opacity: 1;
            transform: translateX(0);
            visibility: visible;
            transition: opacity 0.35s, transform 0.5s $snappy, visibility 0s linear 0s;
        }

        #more {
            transform: translateX(50%);
        }
        &.more #more {
            opacity: 1;
            transform: translateX(0);
            visibility: visible;
            transition: opacity 0.35s, transform 0.5s $snappy, visibility 0s linear 0s;
        }

        #patients-visitors {
            transform: translateX(50%);
        }
        &.patients-visitors #patients-visitors {
            opacity: 1;
            transform: translateX(0);
            visibility: visible;
            transition: opacity 0.35s, transform 0.5s $snappy, visibility 0s linear 0s;
        }
        &.patients-visitors #more {
            transform: translateX(-50%);
        }

        #events {
            transform: translateX(50%);
        }
        &.events #events {
            opacity: 1;
            transform: translateX(0);
            visibility: visible;
            transition: opacity 0.35s, transform 0.5s $snappy, visibility 0s linear 0s;
        }
        &.events #more {
            transform: translateX(-50%);
        }

        #news-resources {
            transform: translateX(50%);
        }
        &.news-resources #news-resources {
            opacity: 1;
            transform: translateX(0);
            visibility: visible;
            transition: opacity 0.35s, transform 0.5s $snappy, visibility 0s linear 0s;
        }
        &.news-resources #more {
            transform: translateX(-50%);
        }

        #smh-foundation {
            transform: translateX(50%);
        }
        &.smh-foundation #smh-foundation {
            opacity: 1;
            transform: translateX(0);
            visibility: visible;
            transition: opacity 0.35s, transform 0.5s $snappy, visibility 0s linear 0s;
        }
        &.smh-foundation #more {
            transform: translateX(-50%);
        }

        #careers {
            transform: translateX(50%);
        }
        &.careers #careers {
            opacity: 1;
            transform: translateX(0);
            visibility: visible;
            transition: opacity 0.35s, transform 0.5s $snappy, visibility 0s linear 0s;
        }
        &.careers #more {
            transform: translateX(-50%);
        }
    }

    @include lg {
        height: 100px;

        .header-top {
            position: relative;
            z-index: 2;
            height: 50px;
            background-color: $dark-blue;

            form.search {
                position: absolute;
                z-index: 2;
                right: 0;
                top: 0;
                display: block;
                width: 50px;
                height: 50px;

                &.active input {
                    width: 360px;
                    padding: 0.5rem 65px 0.5rem 1rem;
                    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.35);
                }

                input {
                    position: absolute;
                    right: 0;
                    top: 0;
                    display: block;
                    width: 0;
                    height: 50px;
                    padding: 0.5rem 0;
                    border: none;
                    background: $white;
                    border-radius: 0;
                    font-size: 0.9375rem;
                    color: $body-color;
                    transition: width 1s $snappy, padding 1s, box-shadow 0.5s;

                    &:focus {
                        outline: none;
                    }
                }

                ::-webkit-input-placeholder {
                    color: $body-color;
                }
                ::-moz-placeholder {
                    color: $body-color;
                }
                :-ms-input-placeholder {
                    color: $body-color;
                }
                :-moz-placeholder {
                    color: $body-color;
                }

                button {
                    position: absolute;
                    z-index: 3;
                    right: 0;
                    top: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    padding: 0;
                    font-size: 1.125rem;
                    background-color: $teal;
                    border: none;
                    color: $white;
                    cursor: pointer;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }

        a.logo {
            position: relative;
            top: 12px;
            display: inline-block;
            width: auto;
            background-color: transparent;

            img {
                width: 252px;
                height: 25px;
            }
        }

        a.mobile-nav-toggle {
            display: none;
        }

        a.desktop-nav-toggle {
            position: absolute;
            z-index: 1;
            right: 50px;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            padding: 10px 20px;
            font-family: $font-family-sans-alt;
            font-size: 0.875rem;
            font-weight: $font-weight-medium;
            color: $white;

            span {
                border-bottom: 1px solid transparent;
                transition: border-bottom-color 0.25s;
            }

            &:hover span {
                border-bottom: 1px solid $white;
            }

            svg {
                transition: transform 0.5s $ease-out-back-easy;
            }
        }

        a.desktop-search-toggle {
            position: absolute;
            z-index: 1;
            right: 0;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            font-size: 1.125rem;
            background-color: $teal;
            color: $white;
        }

        nav {
            z-index: 1;
            top: 50px;
            opacity: 1;
            transform: translateY(0);
            height: 50px;
            overflow: visible;
            background-color: $blue;

            ul.main-menu {
                li {
                    white-space: nowrap;
                    margin-right: 20px;

                    &:nth-child(7) {
                        margin-right: 0;
                    }

                    &.separator {
                        display: block;
                        width: 1px;
                        height: 50px;
                        background-color: $dark-blue;
                    }
                }

                a span {
                    border-bottom: 1px solid transparent;
                    transition: border-bottom-color 0.25s;
                }

                a:hover span {
                    color: $white;
                    border-bottom: 1px solid $white;
                }
            }

            ul.sub-menu {
                margin-bottom: 0;

                a {
                    padding: 5px 0;
                    font-size: 1rem;
                    color: $body-color;

                    &:focus,
                    &:active,
                    &:hover {
                        color: $body-color;
                    }

                    em {
                        display: block;
                        margin-bottom: 0.5rem;
                        font-size: 0.75rem;
                        letter-spacing: 0.4px;
                    }
                }
            }

            #more li {
                margin-right: 10px;
            }

            #main-menu {
                transform: translateX(0);
            }

            &.main-menu #main-menu {
                z-index: 10;
                opacity: 1;
                transform: translateX(0);
                visibility: visible;
                transition: opacity 0.35s, transform 0.5s $snappy, visibility 0s linear 0s;
            }

            #physicians {
                transform: translateX(0) translateY(-100%);
            }
            &.physicians #physicians {
                opacity: 1;
                transform: translateX(0);
                visibility: visible;
                transition: opacity 0.35s, transform 0.5s $snappy, visibility 0s linear 0s;
            }
            &.physicians a[href="#physicians"] svg {
                transform: rotate(180deg);
            }

            #services {
                transform: translateX(0) translateY(-100%);
            }
            &.services #services {
                opacity: 1;
                transform: translateY(0);
                visibility: visible;
                transition: opacity 0.35s, transform 0.5s $snappy, visibility 0s linear 0s;
            }
            &.services a[href="#services"] svg {
                transform: rotate(180deg);
            }

            #locations {
                transform: translateX(0) translateY(-100%);
            }
            &.locations #locations {
                opacity: 1;
                transform: translateY(0);
                visibility: visible;
                transition: opacity 0.35s, transform 0.5s $snappy, visibility 0s linear 0s;
            }
            &.locations a[href="#locations"] svg {
                transform: rotate(180deg);
            }

            #more {
                transform: translateX(0) translateY(-100%);
            }
            &.more #more {
                opacity: 1;
                transform: translateY(0);
                visibility: visible;
                transition: opacity 0.35s, transform 0.5s $snappy, visibility 0s linear 0s;
            }

            #patients-visitors {
                transform: translateX(0) translateY(-100%);
            }
            &.patients-visitors #patients-visitors {
                opacity: 1;
                transform: translateY(0);
                visibility: visible;
                transition: opacity 0.35s, transform 0.5s $snappy, visibility 0s linear 0s;
            }
            &.patients-visitors #more {
                transform: translateX(0) translateY(-100%);
            }

            #events {
                transform: translateX(0) translateY(-100%);
            }
            &.events #events {
                opacity: 1;
                transform: translateY(0);
                visibility: visible;
                transition: opacity 0.35s, transform 0.5s $snappy, visibility 0s linear 0s;
            }
            &.events #more {
                transform: translateX(0) translateY(-100%);
            }

            #news-resources {
                transform: translateX(0) translateY(-100%);
            }
            &.news-resources #news-resources {
                opacity: 1;
                transform: translateY(0);
                visibility: visible;
                transition: opacity 0.35s, transform 0.5s $snappy, visibility 0s linear 0s;
            }
            &.news-resources #more {
                transform: translateX(0) translateY(-100%);
            }

            #smh-foundation {
                transform: translateX(0) translateY(-100%);
            }
            &.smh-foundation #smh-foundation {
                opacity: 1;
                transform: translateY(0);
                visibility: visible;
                transition: opacity 0.35s, transform 0.5s $snappy, visibility 0s linear 0s;
            }
            &.smh-foundation #more {
                transform: translateX(0) translateY(-100%);
            }

            #careers {
                transform: translateX(0) translateY(-100%);
            }
            &.careers #careers {
                opacity: 1;
                transform: translateY(0);
                visibility: visible;
                transition: opacity 0.35s, transform 0.5s $snappy, visibility 0s linear 0s;
            }
            &.careers #more {
                transform: translateX(0) translateY(-100%);
            }
        }

        #main-menu {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 10;
            opacity: 1;
            visibility: visible;
            width: 100%;
            background-color: $blue;
            transform: translateX(0) !important;

            .main-menu {
                display: flex;
                justify-content: space-between;
                max-width: map-get($container-max-widths, lg) - ($spacer * 2);
                margin: 0 auto;

                a {
                    height: 50px;
                    padding: 10px 0;
                    border-bottom: none;
                    font-weight: $font-weight-medium;
                }
            }
        }

        #more {
            .panel-heading {
                display: none;
            }

            .main-menu {
                display: flex;
                justify-content: space-between;
                max-width: map-get($container-max-widths, lg) - ($spacer * 2);
                margin: 0 auto;

                a {
                    height: 75px;
                    padding: 10px 0;
                    border-bottom: none;
                    font-weight: $font-weight-medium;
                }
            }
        }
    }

    @include xl {
        nav #more li {
            margin: 0 20px;
        }

        nav #main-menu li {
            &:nth-child(6) {
                margin-left: auto;
            }

            &.separator {
                display: none;
            }
        }

        #main-menu {
            li {
                &:nth-child(6) {
                    margin-left: auto;
                }

                &.separator {
                    display: none;
                }
            }

            .main-menu {
                justify-content: flex-start;
                max-width: map-get($container-max-widths, xl) - ($spacer * 2);
            }
        }
    }
}

.nav-panel {
    @include fill;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 30px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.35s, transform 0.5s $snappy, visibility 0s linear 0.5s;

    .panel-heading {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        border-bottom: 1px solid $teal;

        span {
            @include eyebrow;
            color: $white;
        }

        a {
            @include eyebrow;
            color: $white;
            text-decoration: underline;

            &:hover {
                color: $yellow;
            }
        }
    }

    .panel-subheading {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        border-bottom: 1px solid $teal;

        span {
            display: block;
            margin-bottom: 0.375rem;
            text-transform: uppercase;
            font-size: 0.8125rem;
            font-weight: $font-weight-bold;
            color: $white;
        }

        a {
            display: block;
            margin-bottom: 0.375rem;
            text-transform: uppercase;
            font-size: 0.8125rem;
            font-weight: $font-weight-bold;
            color: $yellow;
            text-decoration: underline;
        }
    }

    .btn-block {
        margin-bottom: 25px;
    }

    .divider {
        position: relative;
        display: flex;
        justify-content: center;
        margin-bottom: 25px;

        &::before {
            content: " ";
            position: absolute;
            z-index: 1;
            left: 0;
            top: 50%;
            width: 100%;
            height: 1px;
            background-color: $blue;
        }

        span {
            position: relative;
            z-index: 2;
            display: block;
            padding: 0 10px;
            text-transform: uppercase;
            font-size: 0.625rem;
            font-weight: $font-weight-bold;
            background-color: $dark-blue;
            color: $white;
        }
    }

    form.search {
        position: relative;
        margin-bottom: 40px;

        input {
            display: block;
            width: 100%;
            padding: 0.5rem 2rem 0.5rem 0;
            border: none;
            background: none;
            border-bottom: 1px solid $white;
            border-radius: 0;
            font-size: 0.875rem;
            font-weight: $font-weight-medium;
            color: $white;

            &:focus {
                outline: none;
                border-bottom-color: $teal;
            }
        }

        ::-webkit-input-placeholder {
            color: $white;
        }
        ::-moz-placeholder {
            color: $white;
        }
        :-ms-input-placeholder {
            color: $white;
        }
        :-moz-placeholder {
            color: $white;
        }

        button {
            position: absolute;
            right: 0;
            top: 0;
            width: 32px;
            height: 38px;
            padding: 0;
            border: none;
            background: none;
            color: $white;
            cursor: pointer;

            &:focus {
                outline: none;
                color: $teal;
            }

            .icon {
                margin: 0;
            }
        }
    }

    @include lg {
        position: absolute;
        z-index: 11;
        left: calc(50% - #{(map-get($container-max-widths, lg) - ($spacer * 2)) / 2});
        top: 0;
        width: map-get($container-max-widths, lg) - ($spacer * 2);
        height: auto;
        background-color: $white;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
        opacity: 1;
        overflow: auto;

        .panel-heading {
            span {
                color: $body-color;
            }
        }

        .panel-subheading {
            border-bottom-color: $light-grey;

            span {
                font-family: $font-family-sans-alt;
                font-weight: $font-weight-medium;
                color: $dark-blue;
            }

            a {
                display: none;
            }
        }

        .btn-block {
            margin-bottom: 40px;
        }

        .divider {
            span {
                background-color: $white;
                color: $blue;
            }

            &::before {
                background-color: $light-grey;
            }
        }

        form.search {
            input {
                padding: 0.5rem 0 0.5rem 2rem;
                font-size: 1rem;
                color: $dark-blue;
                border-bottom-color: $teal;
            }

            ::-webkit-input-placeholder {
                color: $dark-blue;
            }
            ::-moz-placeholder {
                color: $dark-blue;
            }
            :-ms-input-placeholder {
                color: $dark-blue;
            }
            :-moz-placeholder {
                color: $dark-blue;
            }

            button {
                left: 0;
                right: auto;
                color: $dark-blue;
            }
        }
    }

    @include xl {
        left: calc(50% - #{(map-get($container-max-widths, xl) - ($spacer * 2)) / 2});
        width: map-get($container-max-widths, xl) - ($spacer * 2);
    }
}

.search-panel {
    @include lg {
        position: absolute;
        z-index: 9;
        top: 50px;
        display: flex;
        padding: 50px 60px;

        &::after {
            content: " ";
            position: absolute;
            left: 320px;
            top: 0;
            width: 1px;
            height: 100%;
            background-color: $light-grey;
        }

        .panel-heading {
            display: none;
        }

        .search-wrap {
            position: relative;
            width: 200px;
            margin: 0 auto 0 10px;
        }

        .menu-wrap {
            width: 190px;
            margin: 0 10px;
        }

        .menu-full {
            width: 400px;

            ul {
                columns: 2;
            }

            .panel-subheading a {
                display: block;
                text-transform: none;
                font-size: 0.9375rem;
                color: $teal;
            }
        }
    }

    @include xl {
        .menu-wrap {
            width: 320px;
        }

        .menu-full {
            width: 660px;
        }
    }
}

.menu-panel {
    @include lg {
        width: 100%;
        left: 0;
        background-color: $darker-blue;

        > * {
            width: map-get($container-max-widths, lg) - ($spacer * 2);
            margin: 0 auto;
        }

        .panel-heading {
            margin-bottom: 10px;

            span {
                font-weight: $font-weight-medium;
                color: $white;
            }

            a {
                text-transform: none;
                font-family: $font-family-sans-serif;
                font-size: 0.9375rem;
            }
        }

        ul.sub-menu {
            a {
                margin-bottom: 5px;
                padding: 2px 0;
                color: $white;
                border-bottom: 1px solid transparent;
                transition: border-bottom-color 0.25s;

                &:focus,
                &:active,
                &:hover {
                    color: $white;
                    border-bottom: 1px solid $white;
                }
            }
        }
    }
}

html.mobile-nav-active {
    overflow: hidden;

    body {
        overflow: hidden;
    }

    header {
        a.logo {
            transform: translateX(-50%);
        }

        a.mobile-nav-toggle {
            width: 100%;

            svg.icon {
                transform: rotate(315deg);
            }
        }

        nav {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @include lg {
        header a.logo {
            transform: translateX(0);
        }
    }
}

html.desktop-nav-active {
    header {
        a.desktop-nav-toggle {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}

.fixed-header-wrap {
    @include lg {
        height: 100px;
    }
}

header.stuck {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    transform: translateY(-100%);
    transition: transform 0.5s $snappy;

    &.active {
        transform: translateY(0);
    }
}
