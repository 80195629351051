section.filters {
    position: relative;
    z-index: 1;
}

.filters-wrap {
    position: relative;
    height: 50px;
    background-color: $dark-blue;

    @include md {
        display: flex;
        align-items: center;
        height: 60px;

        &.stuck {
            position: fixed;
            top: 0;
            width: 100%;
        }

        > * {
            position: relative;
            z-index: 2;
        }

        &::after {
            content: "";
            @include fill;
            z-index: 1;
            width: 100vw;
            background-color: $dark-blue;
        }
    }
}

.filters-header {
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 10px 30px;

    > * {
        position: relative;
        z-index: 2;
    }

    &::after {
        content: "";
        @include fill;
        z-index: 1;
        width: 200%;
        background-color: $dark-blue;
    }

    .filter-by {
        position: absolute;
        left: 30px;
        top: 50%;
        @include smallcaps;
        color: $white;
        opacity: 0;
        transform: translateY(-200%);
        transition: opacity 0.25s, transform 0.5s $snappy;
    }

    .filters-wrap.active & .filter-by {
        opacity: 1;
        transform: translateY(-50%);
    }

    @include md {
        height: 60px;

        .filter-by {
            display: none;
        }
    }
}

.filters-toggle {
    margin-left: auto;
    transition: transform 0.5s $ease-out-back;

    .filters-wrap.active & {
        transform: rotate(135deg);
    }

    .filters-wrap.active &.filters-label {
        transform: rotate(0);

        .icon {
            transform: rotate(180deg);
        }
    }
}

form.filters-search {
    position: relative;
    flex-shrink: 0;
    width: 166px;
    transition: opacity 0.25s, transform 0.5s $snappy;

    .filters-wrap.active & {
        opacity: 0;
        transform: translateY(-100%);
    }

    input {
        display: block;
        width: 100%;
        padding: 0.25rem 0 0.25rem 1.5rem;
        border: none;
        background: none;
        border-bottom: 1px solid $white;
        border-radius: 0;
        font-size: 0.875rem;
        font-weight: $font-weight-medium;
        color: $white;

        &:focus {
            outline: none;
            border-bottom-color: $teal;
        }
    }

    ::-webkit-input-placeholder { color: $white; }
    ::-moz-placeholder { color: $white; }
    :-ms-input-placeholder { color: $white; }
    :-moz-placeholder { color: $white; }

    button {
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 30px;
        padding: 0;
        border: none;
        background: none;
        color: $white;

        &:focus {
            outline: none;
            color: $teal;
        }

        .icon {
            margin: 0;
        }
    }

    @include md {
        .filters-wrap.active & {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.filters-groups {
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    padding: 0 30px 20px 30px;
    background-color: $dark-blue;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.5s, transform 0.5s $snappy, visibility 0s linear 0.5s;

    > * {
        position: relative;
        z-index: 2;
    }

    &::after {
        content: "";
        @include fill;
        z-index: 1;
        width: 200%;
        background-color: $dark-blue;
    }

    .filters-wrap.active & {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.5s, transform 0.5s $snappy, visibility 0s linear 0s;
    }

    @include md {
        position: relative;
        left: 0;
        top: 0;
        display: flex;
        padding: 0;
        visibility: visible;
        opacity: 1;
        transform: translateY(0);

        &::after {
            display: none;
        }
    }
}

.filters-group {
    border-bottom: 1px solid $teal;

    &:first-child {
        border-top: 1px solid $teal;
    }

    @include md {
        border-bottom: none;

        &:first-child {
            border-top: none;
        }
    }
}

.filters-label,
.filters-toggle {
    display: flex;
    align-items: center;
    padding: 15px 0;
    text-transform: uppercase;
    font-size: 0.8125rem;
    font-weight: $font-weight-medium;
    letter-spacing: 0.3px;
    color: $white !important;
}

.filters-label {
    cursor: pointer;

    .icon {
        margin-left: auto;
        transition: transform 0.5s $ease-out-back-easy;
    }

    .filters-group.active & .icon {
        transform: rotate(180deg);
    }

    @include md {
        height: 60px;
        margin-right: 1rem;

        .icon {
            margin-left: 0.5rem;
        }
    }
}

.filters-toggle {
    display: flex;
    justify-content: center;

    @include md {
        display: none;
    }
}

.filters-toggle.filters-label {
    min-width: 50px;

    @include md {
        display: flex;
    }
}

.filters-dropdown {
    display: flex;
    flex-wrap: wrap;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.25s, transform 0.5s $snappy, visibility 0s linear 0.5s;
    font-size: 0.9375rem;

    a {
        display: block;
        width: 100%;
        color: $white;
    }

    .filters-group.active & {
        height: auto;
        margin: 15px 0;
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.25s, transform 0.5s $snappy, visibility 0s linear 0s;
    }

    &.filters-dropdown-sm a {
        width: 100% !important;
    }

    @include sm {
        a {
            width: 50%;
        }
    }

    @include md {
        position: absolute;
        left: 0;
        top: 100%;
        width: 235px;
        max-height: 140px;
        padding: 30px;
        box-shadow: 0 0 2px rgba(0,0,0,0.25);
        background-color: $lightest-grey;
        overflow: scroll;

        a {
            width: 100%;
            margin-bottom: 0.5rem;
            color: $body-color;
        }

        .filters-group.active & {
            margin: 0;
            max-height: 140px;
        }

        &.filters-dropdown-sm {
            width: 60px;
            padding: 30px 20px;
        }
    }

    @include lg {
        width: 450px;

        a {
            width: 50%;
        }
    }

    @media (max-width: 767px) {
        .filters-group.fixed & {
            height: auto;
            margin: 15px 0;
            transform: translateY(0);
            transition: opacity 0.25s, transform 0.5s $snappy, visibility 0s linear 0s;
        }

        .filters-wrap.active .filters-group.fixed & {
            visibility: visible;
            opacity: 1;
        }
    }
}

.filters-switch {
    position: relative;
    display: block;
    width: 24px;
    height: 14px;
    margin: 0 0.5rem;

    &::before {
        content: " ";
        position: absolute;
        left: 0;
        top: calc(50% - 4px);
        width: 24px;
        height: 8px;
        background-color: $dark-grey;
        border-radius: 4px;
    }

    &::after {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        width: 14px;
        height: 14px;
        background-color: $white;
        border-radius: 7px;
        box-shadow: 0 1px 2px rgba(0,0,0,0.5);
    }

    &.active {
        &::before {
            background-color: $teal;
        }

        &::after {
            transform: translateX(10px);
        }
    }
}

.selected-filters {
    position: relative;
    top: -15px;

    .meta-label {
        color: $grey;
    }

    @include md {
        top: -30px;
    }

    @include lg {
        top: -50px;
    }
}

.clear-filters {
    white-space: nowrap;

    svg.icon {
        position: relative;
        top: 2px;
        transform: rotate(45deg);
    }
}
