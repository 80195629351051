@import "plugins";
@import "variables";
@import "mixins";

@import "bootstrap/scss/bootstrap-reboot";
@import "bootstrap/scss/bootstrap-grid";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/type";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/nav";

@import "utilities";
@import "typography";
@import "components";
@import "header";
@import "footer";
@import "sidebar";
@import "hero";
@import "forms";
@import "filters";
@import "blog";
@import "events";
@import "foundation";
@import "announcement-bar";

html,
body {
    height: 100%;
    background-color: $white;
}

main,
footer {
    opacity: 1;
    transition: opacity 1s;
}

html.loading {
    main,
    footer {
        opacity: 0;
        transition: opacity 0.5s;
    }
}

.loading:not(html) {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s, transform 0.5s $ease-out;
}

.animate {
    opacity: 0;
    transform: translateY(40px);
    transition: opacity 0.5s, transform 1s cubic-bezier(0, 0, 0, 1);

    &.active {
        opacity: 1;
        transform: translateY(0);
    }
}

.container {
    padding-left: $spacer;
    padding-right: $spacer;
}

img,
svg,
video,
iframe {
    max-width: 100%;
    height: auto;
}

.content-wrap {
    overflow: hidden;
}

section.content,
section.header {
    margin: 30px 0;

    p,
    ul,
    ol {
        a:not([class]) {
            font-weight: $font-weight-bold;
            color: $body-color;
            border-bottom: 1px solid $teal;
            transition: color 0.25s, border 0.25s;

            &:hover {
                color: $teal;
                border-bottom-color: transparent;
            }
        }
    }

    @include md {
        margin: 50px 0;
    }

    @include lg {
        margin: 70px 0;
    }
}

// Custom Column Gutters
.row.gutter-60 {
    margin-right: -30px;
    margin-left: -30px;
}

.gutter-60 > [class^="col-"],
.gutter-60 > [class^=" col-"] {
    padding-right: 30px;
    padding-left: 30px;
}

.row.gutter-40 {
    margin-right: -20px;
    margin-left: -20px;
}

.gutter-40 > [class^="col-"],
.gutter-40 > [class^=" col-"] {
    padding-right: 20px;
    padding-left: 20px;
}

.row.gutter-20 {
    margin-right: -10px;
    margin-left: -10px;
}

.gutter-20 > [class^="col-"],
.gutter-20 > [class^=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
}

.row.gutter-10 {
    margin-right: -5px;
    margin-left: -5px;
}

.gutter-10 > [class^="col-"],
.gutter-10 > [class^=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
}

.row.gutter-2 {
    margin-right: -1px;
    margin-left: -1px;
}

.gutter-2 > [class^="col-"],
.gutter-2 > [class^=" col-"] {
    padding-right: 1px;
    padding-left: 1px;
}

/**
 * Magnific Popup
 */

.mfp-bg {
    background-color: $white;
}

.mfp-fade.mfp-bg {
    opacity: 0;
    transition: opacity 0.5s;
}

.mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.85;
}

.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
    transform: translateY(40px);
    transition: opacity 0.5s, transform 1s $snappy;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
    transform: translateY(0);
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
    transform: translateY(40px);
}

.mfp-iframe-scaler iframe {
    box-shadow: none;
}
