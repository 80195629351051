body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p + p {
    margin-top: -0.5rem;
}

h1, .h1 {
    font-weight: $font-weight-bold;
    font-family: $font-family-serif;

    &.light { color: $lightest-grey; }
    &.dark { color: $dark-brown; }

    &.large {
        font-size: 2.1875rem; // 35px
    }

    @include md {
        font-size: 2rem; // 32px

        &.large {
            font-size: 2.8125rem; // 45px
        }
    }
}

h2, .h2 {
    font-weight: $font-weight-semibold;

    &.light { color: $white; }
    &.dark { color: $dark-brown; }

    @include md {
        font-size: 2rem; // 32px
    }
}

h3, .h3 {
    font-weight: $font-weight-semibold;
    letter-spacing: 0.18px;

    &.light { color: $lightest-grey; }
    &.dark { color: $dark-brown; }

    @include md {
        font-size: 1.5625rem; // 25px
    }
}

h4, .h4 {
    font-weight: $font-weight-semibold;
    letter-spacing: 0.25px;
    line-height: 1.5;

    &.light { color: $lightest-grey; }
    &.dark { color: $dark-brown; }

    @include md {
        font-size: 1.125rem; // 18px
    }
}

h5, .h5 {
    font-weight: $font-weight-medium;
    letter-spacing: 0.22px;
    line-height: 1.5;

    &.light { color: $lightest-grey; }
    &.dark { color: $dark-brown; }

    @include md {
        font-size: 1rem; // 16px
    }
}

h6, .h6 {
    font-weight: $font-weight-bold;
    font-family: $font-family-serif;
    letter-spacing: 0.14px;
    line-height: 1.5;
    color: $dark-brown;

    &.light { color: $lightest-grey; }
    &.dark { color: $dark-brown; }
}

h1,h2,h3,h4,h5,h6,
.eyebrow {
    &.divider {
        position: relative;
        margin-bottom: 1em;
        padding-bottom: 0.5rem;
        text-decoration: none;

        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: $light-grey;
        }
    }

    &.divider-extend::after,
    .divider-extend &::after {
        width: 150%;
    }

    &.divider-primary::after {
        background-color: $primary;
    }

    &.divider-secondary::after {
        background-color: $secondary;
    }

    &.divider-yellow::after {
        background-color: $yellow;
    }
}

a {
    color: $teal;
    transition: color 0.25s, background-color 0.25s;

    &:hover {
        color: darken($teal, 10%);
        text-decoration: none;
    }
}

sup {
    font-size: 0.5em;
}

hr {
    @include md {
        margin: 40px 0;
    }

    @include xl {
        margin: 60px 0;
    }
}

hr.small {
    width: 30px;
    margin: 1rem 0;
}

hr.medium {
    width: 60px;
    margin: 1rem 0;
}

hr.primary { border-top-color: $primary; }
hr.secondary { border-top-color: $secondary; }
hr.yellow { border-top-color: $yellow; }

ul,
ol {
    padding-left: 1rem;
}

ul ul {
    list-style-type: disc;
}

table {
    width: 100%;
    margin: 2rem 0;
    overflow: hidden;
    overflow-x: scroll;

    &.overflow {
        @include gradient-x-three-colors($white, $white, calc(100% - 30px), darken($light-grey, 10%));
    }
}

thead,
tbody {
    width: 100%;
}

th {
    padding: 1rem 5px;
    font-weight: $font-weight-bold;
    vertical-align: top;
    border-bottom: 1px solid $light-grey;

    @include md {
        padding: 1.5rem 5px;
    }
}

td {
    min-width: 120px;
    padding: 1rem 5px;
    vertical-align: top;
    border-bottom: 1px solid $light-grey;

    @include md {
        padding: 1.5rem 5px;
    }
}

.lead {
    font-family: $font-family-sans-alt;
    font-size: 1rem;
    letter-spacing: 0.25px;

    @include md {
        margin-bottom: 1.5em;
    }

    @include lg {
        margin-bottom: 2rem;
    }
}

.btn {
    position: relative;
    min-width: 180px;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    text-align: center;
    font-family: $font-family-sans-alt;
    font-size: 0.75rem;
    letter-spacing: 0.25px;
    transition: color 0.25s, background-color 0.25s, transform 0.25s $ease-out;

    &:last-child {
        margin-right: 0;
    }

    &:active {
        transform: translateY(2px);
    }
}

.btn:not(.btn-block) {
    margin-right: 1rem;
}

.btn-lg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    min-width: 150px;
    padding: 10px 20px;
    border-radius: 30px;
    white-space: normal;
    text-transform: none;
    font-weight: $font-weight-semibold;
    font-size: 0.8125rem;
    line-height: 1.25;
    letter-spacing: 0.5px;

    @include md {
        height: 65px;
        font-size: 0.875rem;
        border-radius: 40px;
    }
}

.btn-primary:hover,
.btn-primary:active {
    background-color: mix($primary, $white, 75%) !important;
    border-color: mix($primary, $white, 75%) !important;
}

.btn-secondary:hover,
.btn-secondary:active {
    background-color: mix($secondary, $white, 75%) !important;
    border-color: mix($secondary, $white, 75%) !important;
}

.btn-primary,
.btn-secondary {
    box-shadow: 0 2px 4px rgba(0,0,0,0.25);

    // Hack for nested Redactor buttons
    a { color: $white; }
}

.btn-outline-primary,
.btn-outline-secondary {
    // Hack for nested Redactor buttons
    a { color: $white; }
}

.btn-light {
    border-color: $lightest-grey;
    background-color: $lightest-grey;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.25);
    color: $dark-blue;
    transition: color 0.25s, box-shadow 0.25s;

    // Hack for nested Redactor buttons
    a { color: $dark-blue; }

    &:hover,
    &:active {
        border-color: $lightest-grey !important;
        background-color: $lightest-grey !important;
        box-shadow: 1px 2px 4px rgba(0,0,0,0.35) !important;
        color: $dark-blue !important;
    }

    .hero & {
        border-color: $white;
        background-color: $white;
        color: $pink;

        &:hover,
        &:active {
            border-color: $white !important;
            background-color: $white !important;
            color: $teal !important;
        }
    }
}

.btn-link {
    min-width: 0;
    padding: .625rem;
    font-weight: $font-weight-bold;
    color: $secondary;
    text-decoration: underline;

    // Hack for nested Redactor buttons
    a { color: $secondary; }
}

.btn-link-offsite {
    min-width: 0;
    padding: .625rem;
    font-weight: $font-weight-bold;
    font-size:  0.8175rem;
    color: $secondary;
    text-decoration: underline;

    // Hack for nested Redactor buttons
    a { color: $secondary; }

    &::after {
        content: " ";
        display: inline-block;
        width: 1rem;
        height: 1rem;
        margin: 0 0 -3px 4px;
        background: url('../img/link-offsite-arrow.svg') 50% 50% no-repeat;
    }
}

@mixin link-medium {
    display: table;
    text-decoration: underline;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-medium;
    color: $dark-blue;
    transition: color 0.25s;

    &:hover {
        color: $blue;
    }
}
.link-medium { @include link-medium; }

p + .link-medium {
    margin-top: -1rem;
}

@mixin link-bold {
    display: table;
    text-decoration: underline;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-bold;
    color: $teal;
    transition: color 0.25s;

    &:hover {
        color: $blue;
    }
}
.link-bold { @include link-bold; }

p + .link-bold {
    margin-top: -1rem;
}

a.discreet {
    color: $body-color;
    text-decoration: none;

    &:hover {
        color: $teal;
        text-decoration: underline;
    }
}

@mixin meta-label {
    display: inline-block;
    min-width: 40px;
    margin-right: 10px;
    vertical-align: middle;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    font-size: 0.625rem;
    color: $blue;
}
.meta-label { @include meta-label; }

@mixin eyebrow {
    display: block;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-family: $font-family-sans-alt;
    font-weight: $font-weight-medium;
    font-size: 0.8125rem;
    letter-spacing: 0.3px;
    color: $dark-blue;
}
.eyebrow { @include eyebrow; }
.meta-heading { @include eyebrow; color: $grey; }

a.eyebrow {
    text-decoration: underline;
    font-weight: $font-weight-bold;
    color: $teal;
}

@mixin smallcaps {
    display: block;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-bold;
    font-size: 0.625rem;
    letter-spacing: 0.18px;
    color: $grey;
}
.smallcaps,
.category { @include smallcaps; }

@mixin yellow-dot {
    &::before {
        content: " ";
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 6px;
        border-radius: 50%;
        vertical-align: middle;
        background-color: $gold;
    }
}
.yellow-dot { @include yellow-dot; }

@mixin affiliation {
    margin-bottom: 0.5rem;
    font-style: italic;
    font-size: 0.75rem;
    letter-spacing: 0.4px;
    color: $body-color;
}
.affiliation {
    @include affiliation;

    &.smh-physicians-network {
        @include yellow-dot;
    }
}

@mixin learn-more-block {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 45px;
    padding: 10px 30px;
    text-transform: uppercase;
    text-align: center;
    font-family: $font-family-sans-alt;
    font-weight: $font-weight-bold;
    font-size: 0.8125rem;
    line-height: 25px;
    letter-spacing: 0.3px;
    background-color: $lightest-grey;
    color: $dark-blue;
    transition: background-color 0.25s;

    &:hover {
        background-color: $teal;
        color: $white;
    }
}
.learn-more-block { @include learn-more-block; }

.textcol-1 { columns: 1; }
.textcol-2 { columns: 2; }
.textcol-3 { columns: 3; }

@include sm {
    .textcol-sm-1 { columns: 1; }
    .textcol-sm-2 { columns: 2; }
    .textcol-sm-3 { columns: 3; }
}

@include md {
    .textcol-md-1 { columns: 1; }
    .textcol-md-2 { columns: 2; }
    .textcol-md-3 { columns: 3; }
}

@include lg {
    .textcol-lg-1 { columns: 1; }
    .textcol-lg-2 { columns: 2; }
    .textcol-lg-3 { columns: 3; }
}

svg.icon {
    width: 0.9em;
    height: 0.9em;
    margin: 0 0.5rem;
    fill: currentColor;
    vertical-align: baseline;
}

:focus,
button:focus {
    outline: 3px auto $teal;
}

.left-rule {
    position: relative;
    padding-left: 15px;

    > *:last-child {
        margin-bottom: 0;
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: $gold;
    }
}

.fw-regular { font-weight: $font-weight-regular !important; }
.fw-medium { font-weight: $font-weight-medium !important; }
.fw-semibold { font-weight: $font-weight-semibold !important; }
.fw-bold { font-weight: $font-weight-bold !important; }

.align-left {
    @include sm {
        float: left;
        margin: 0 1rem 0.5rem 0;
    }
}

.align-right {
    @include sm {
        float: right;
        margin: 0 0 0.5rem 1rem;
    }
}
