section.hero {
    position: relative;
    padding: 30px 0;
    background-color: $lightest-grey;
    overflow: hidden;

    .container {
        position: relative;
    }

    a.jump {
        display: block;

        svg {
            width: 40px;
            height: 20px;
        }
    }

    @include md {
        padding: 80px 0 60px;

        .hero-image-top {
            margin-top: -40px;
        }

        .row {
            position: relative;
            z-index: 2;
        }

        .map-wrap {
            position: absolute;
            left: 10px;
            right: 10px;
            bottom: -60px;
            margin-bottom: 0;
        }
    }

    @include lg {
        .hero-image-top {
            margin-top: -80px;
        }
    }
}

.hero-image {
    display: block;
    max-width: none;
    width: 100%;

    &.animate {
        transform: translateX(40px) translateY(0);
    }

    @include md {
        width: auto;
        height: 260px;
    }

    @include lg {
        height: 320px;
    }
}

section.has-alert {
    @include md {
        padding-bottom: 100px;
    }
}

.alert {
    position: absolute;
    left: 0;
    bottom: -30px;
    width: 100%;
    padding: 30px;
    color: $white;
    background-color: $black;
    border-top-left-radius: 50px;
    box-shadow: 1px 2px 4px rgba(0,0,0,0.25);
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.25s, transform 0.5s $snappy;

    &.active {
        opacity: 1;
        transform: translateY(0);
    }

    h2 {
        font-weight: $font-weight-bold;
        color: $yellow;
    }

    a {
        text-decoration: underline;
        font-weight: $font-weight-bold;
        color: $white;
    }

    a.close {
        position: absolute;
        z-index: 3;
        top: 25px;
        right: 25px;

        svg {
            width: 1.25rem;
            height: 1.25rem;
            margin: 0;
            transform: rotate(45deg);
            fill: $lightest-grey;
        }
    }

    p:last-child {
        margin-bottom: 0;
    }

    @include sm {
        border-top-right-radius: 50px;
    }

    @include md {
        padding: 20px 30px;
        left: 30px;
        bottom: -100px;
        width: calc(100% - 60px);

        h2 {
            text-align: right;
            line-height: 22.5px;
        }

        a.close {
            top: calc(50% - 12px);
        }
    }
}
