// Colors
$white:                     #FFFFFF;
$lightest-grey:             #E8F0F2;
$light-grey:                #D3D3D3;
$grey:                      #76726C;
$dark-grey:                 #8D8D8D;
$black:                     #1B1B19;

$blue:                      #00539B;
$dark-blue:                 #003666;
$darker-blue:               #002A4F;
$teal:                      #01838F;
$gold:                      #F9BB19;
$pink:                      #C14A79;
$dark-brown:                #2A2925;

$primary:                   $pink;
$secondary:                 $teal;

$body-color:                $dark-brown;

// Fonts
$font-family-serif:         'Merriweather', sans-serif;
$font-family-sans-serif:    'Roboto', sans-serif;
$font-family-sans-alt:      'Montserrat', sans-serif;
$font-family-base:          $font-family-sans-serif;

$font-weight-regular:       400;
$font-weight-medium:        500;
$font-weight-semibold:      600;
$font-weight-bold:          700;

$font-size-base:            0.9375rem;
$line-height-base:          1.5;

$headings-margin-bottom:    0.5em;
$headings-font-family:      $font-family-sans-alt;
$headings-font-weight:      $font-weight-medium;
$headings-line-height:      1.2;
$headings-color:            $blue;

$h1-font-size:              1.75rem; // 28px
$h2-font-size:              1.6875rem; // 27px
$h3-font-size:              1.25rem; // 20px
$h4-font-size:              0.9375rem; // 15px
$h5-font-size:              0.9375rem; // 15px
$h6-font-size:              0.9375rem; // 15px

$paragraph-margin-bottom:   1.5rem;

$input-padding-x:           0;
$input-padding-y:           0.5rem;
$input-border-radius:       0;

$btn-font-weight:           $font-weight-bold;
$btn-font-size:             0.75rem;
$btn-line-height:           1.25;
$btn-border-width:          2px;
$btn-border-radius:         40px;
$btn-padding-y:             0.625rem;
$btn-padding-x:             1.5rem;
$btn-padding-y-sm:          0.375rem;
$btn-padding-x-sm:          1rem;

// Transitions
$snappy:                    cubic-bezier(0.42, 0, 0.05, 0.99);
$ease-in:                   cubic-bezier(0.73, 0.08, 1, 1);
$ease-out:                  cubic-bezier(0.38, 0.99, 1, 1);
$ease-out-back:             cubic-bezier(0.29, 0.97, 0, 1.85);
$ease-out-back-easy:        cubic-bezier(0, 1.35, 0.77, 0.99);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1280px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 894px,
  xl: 1166px
);

$spacer: 30px;
$spacers: (
    sm: $spacer,
    md: ($spacer * 2),
    lg: ($spacer * 3)
);

$grid-gutter-width:         20px;
