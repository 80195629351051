.heading-bar {
    position: relative;
    margin: 0 -30px 2rem -30px;
    padding: 30px;
    border-bottom-right-radius: 50px;
    background: $lightest-grey;

    @include sm {
        &::before {
            content: " ";
            position: absolute;
            right: 100%;
            top: 0;
            width: 50vw;
            height: 100%;
            background: $lightest-grey;
        }

        p {
            position: relative;
            margin-left: 60px;

            &::before {
                content: " ";
                position: absolute;
                left: -30px;
                top: -30px;
                width: 1px;
                height: calc(100% + 60px);
                background: $teal;
            }
        }
    }

    @include md {
        h1 {
            min-width: 260px;
        }

        p {
            max-width: 400px;
        }
    }
}

.post-carousel {
    padding: 30px 0;
    background-color: $lightest-grey;

    .excerpt {
        width: 100%;
        margin-bottom: 0;
    }

    .flickity-button {
        position: relative;
        background: transparent;
    }

    .flickity-prev-next-button {
        top: 0;
        width: 30px;
        margin-right: 10px;
        padding: 0;
        transform: none;

        .flickity-button-icon {
            left: 10%;
            width: 80%;

        }
    }

    @include sm {
        padding: 50px 0;

        .excerpt {
            width: calc(50% - 8px);
            margin: 0 8px;
        }

        .flickity-button {
            position: absolute;
        }

        .flickity-prev-next-button {
            width: 30px;
            margin-right: 0;
            padding-top: 28%;
            transform: none;

            &.previous {
                left: -40px;
            }

            &.next {
                right: -40px;
            }

            .flickity-button-icon {
                left: 10%;
                width: 80%;

            }
        }
    }
}

.category-nav {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    height: 60px;
    margin-bottom: 30px;
    border-top: 1px solid $teal;
    border-bottom: 1px solid $teal;

    .category-nav-toggle {
        @include eyebrow;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 20px 0;
        margin: 0;
        color: $dark-blue !important;

        svg.icon {
            width: 1rem;
            height: 1rem;
            fill: $teal;
            transition: transform 0.5s $ease-out-back-easy;
        }
    }

    > ul {
        position: absolute;
        z-index: 1;
        left: 0;
        top: calc(100% + 1px);
        width: 100%;
        padding: 10px;
        list-style: none;
        background: $lightest-grey;
        box-shadow: 0 0 2px rgba(0,0,0,0.25);
        height: 0;
        visibility: hidden;
        opacity: 0;
        transform: translateY(-20px);
        transition: opacity 0.25s, transform 0.5s $snappy, visibility 0s linear 0.5s;

        a {
            display: block;
            padding: 5px;
            font-weight: $font-weight-regular;
            border-bottom: none !important;
        }
    }

    &.active {
        .category-nav-toggle svg.icon {
            transform: rotate(135deg);
        }

        > ul {
            height: auto;
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
            transition: opacity 0.25s, transform 0.5s $snappy, visibility 0s linear 0s;
        }
    }

    .category-nav-overflow {
        display: none;
    }

    @include md {
        margin-bottom: 60px;

        .category-nav-toggle {
            display: none;
        }

        > ul {
            position: relative;
            left: 0;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin: 0;
            height: auto;
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
            background-color: transparent;
            box-shadow: none;

            a {
                padding: 10px 15px;
                text-transform: uppercase;
                font-size: 0.8125rem;
                font-weight: $font-weight-bold;
                letter-spacing: 0.15px;
                color: $blue !important;
                white-space: nowrap;
            }
        }

        .category-nav-overflow {
            position: relative;
            display: block;

            a {
                color: $blue !important;
            }

            svg.icon {
                transition: transform 0.5s $ease-out-back-easy;
            }

            > ul {
                position: absolute;
                z-index: 1;
                left: calc(50% - 150px);
                top: calc(100% + 11px);
                width: 220px;
                padding: 10px;
                list-style: none;
                background: $lightest-grey;
                box-shadow: 0 0 2px rgba(0,0,0,0.25);
                height: 0;
                visibility: hidden;
                opacity: 0;
                transform: translateY(-20px);
                transition: opacity 0.25s, transform 0.5s $snappy, visibility 0s linear 0.5s;
            }

            &.active {
                svg.icon {
                    transform: rotate(180deg);
                }

                > ul {
                    height: auto;
                    visibility: visible;
                    opacity: 1;
                    transform: translateY(0);
                    transition: opacity 0.25s, transform 0.5s $snappy, visibility 0s linear 0s;
                }
            }
        }
    }

    @include lg {
        > ul {
            a {
                padding: 10px 20px;
            }
        }

        .category-nav-overflow > ul {
            width: 240px;
        }
    }
}
