.foundation-logo {
    max-width: none;

    @include md {
        float: right;
        width: 280px;
        margin-right: calc((50vw - (720px / 2) + 10px) * -1);
    }

    @include lg {
        width: 350px;
        margin-right: calc((50vw - (894px / 2) + 10px) * -1);
    }

    @include xl {
        width: 350px;
        margin-right: 0;
    }
}
